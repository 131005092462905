.content.emissions{
  .last {
    background-color: $vert;

    .titlePage {
      padding: 0;
      * {
        color: white;
        font-family: $font_primary;
      }
    }

    .row {
      justify-content: space-between;
    }

    .row_container {
      background-color: $gris-clair;
      position: relative;
      top: 20px;
      margin: auto;

      .col-md-3.custom-height.image {
        padding: 0 !important;
        margin: 0 !important;
      }

      .contentEmissionPodcast {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 1px;
          height: 80%;
          background-color: #d9d9d9;;
          display: inline-block;
          top: 10%;
          right: -15px;
          z-index: 9;

          @media only screen and (max-width: 1220px) {
            display: none;
          }
        }
      }


      .contentEmission {
        padding: 40px 0;
        font-weight: 300;
        margin: auto;

        h2 {
          margin: 15px 0; 
          font-weight: bold;     
          a {
            color: $noir;
            text-decoration: none;
          }
        }

        p {
          font-family: $font_secondary;
          padding-right: 20px;
          line-height: 1.71;
        }

        .nbr_video {
          font-size: 12px;
          font-family: $font_primary;
          letter-spacing: 2px;
          text-transform: uppercase;
        }
      }

      .episodeList{
        max-height: 400px;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 40px 40px 40px 0;

        span {
          margin-bottom: 15px;
          font-family: $font_primary;
        }
      }

      .episodeListContent {
        display: flex;
        background-color: white;
        margin-bottom: 10px;

        .img-container {

          min-width: 100px;
          width: 100px;
          height: 100px;
          
          img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .episodeListInfo {
          padding: 10px;

          p {
            line-height: 1;
            margin-bottom: 5px;
            font-weight: bold;
          }

          span {
            font-size: 12px;
            letter-spacing: 2px;
          }

          a {
            color: $noir;
            font-size: 15px;
            font-family: $font_secondary;
          }
        }
      }
    }
    .container-emissions-episodes {
      position: relative;
      top: 60px;
    }
  }

  @media only screen and (max-width: 768px) {
    .col-md-3{
        max-width: 100%;
        flex: 0 0 100%;
        padding-right: 10px;
        padding-left: 10px;
    } 
    .contentEmission {
      padding: 20px !important;
    }

    .custom-height.image {
      //padding: 20px !important;
    }
  }

  @media only screen and (max-width: 1200px) {
 
    .episodeList, .contentEmission {
      padding: 20px !important;
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
    .custom-width-emission {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }

  .last-emissions-episodes {
    padding: 1em 0 0 !important;
  }

  @media only screen and (max-width: 1200px) {
    .podcast-height{
      min-height: 300px;
      }
    
  }

}
