.donsdiv{
    background-color: $gris-clair;
    padding: 50px;

    h1{
        font-size: 50px;
        font-weight: 800;
        line-height: 1.1;
        color: $noir;
        margin: 0 0 20px 0;
    }
}

.don__title{
    font-family: $font_secondary;
    font-weight: 300;
    color: $noir;
    margin: 2em 0 0.5em;
    font-size: 25px;
}

.don{
    background-color: $vert;
    color: #FFF; 
    padding-top: 1em;
    padding-bottom: 1em;
    h3{
        font-size: 0.8175em;
        margin-bottom: 1em;
    }
    img{
        width: auto;
        display: block;
    }
    
    .paypal-link{
        background-color: #FFF;
        // @include border-radius(3px);
        display: table;
        padding: 0.5em 1em;
    }
    .compte{
        &__title{
            font-weight: 800;
            display: block;
        }
        &__number{
            font-size:1.5em; 
        }
    }
}