.title {
    font-weight: bold;
    font-size: 40px;
    padding: 1em 0;
    text-align: center;
    font-family: $font_primary;
    position: relative;

    span {
        padding: 0 20px;
        background: white;
    }

    &:before {
        content: "";
        width: 100%;
        height: 1px;
        background-color: #d9d9d9;
        display: inline-block;
        vertical-align: middle;

        position: absolute;
        top: 50%;
        left: 0;
        z-index: -1;
    }
}

h1 {
    font-weight: bold;
    color: $noir;
    font-size: 25px;

    a {
        color: $noir;
    }
}