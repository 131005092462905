header {
    background: #FFFFFF;
    // box-shadow: 0 2px 4px 0 rgba(229,229,229,0.50);
    padding: 1em 0;

    .container {
        position: relative;
        max-width: calc(100% - 40px);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media only screen and (min-width: 1200px) {
            max-width: 1400px;
        }
        @media only screen and (max-width: 1200px) {
           display: block;
        }
    }

    .flex{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
    }

    .prenav {
        
        @media only screen and (max-width: 850px) {
            display: none;
        }

        @media only screen and (min-width: 740px) {
            //position: absolute;
            //right: 0.9375em;
            //top: 5px;
        }
        
        @media only screen and (min-width: 1100px) {
            //top: 5px;
        }


        .btn-header {
            font-size: 0.75em;
            font-weight: 800;
            text-transform: uppercase;
            padding: 10px 20px;
            background-color: $vert;
            color: white;
            border-radius: 50px;
            letter-spacing: 2px;
            line-height: .8;

            transition: .3s ease;


            &:hover {
                color: white;
                background-color: $noir;
                color: #fff;
                text-decoration: none;
            }
        }
        
    }

    .logo {
        width: 320px;
        min-width: 320px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
            width: 135px;
            
        }
        
        @media only screen and (max-width: 370px) {
            width: 135px;
        }

        @media only screen and (max-width: 740px) {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .baseline {
        font-size: 11px;
        font-family: $font_secondary;
        font-weight: 300;
        line-height: 16px;
        color: $noir;
        border-left: 1px solid $gris;
        padding-left: 25px;
        margin-left: 25px;
        margin-bottom: 0;

        @media only screen and (max-width: 370px) {
            display: none;
        }
    }

    .main_nav {
        display: flex;
        justify-content: flex-end;

        .menu {
            margin-top: 15px;
            margin-bottom: 0;
        }

        li {
            display: inline-block;
            position: relative;
        }

        .current-menu-item a{
            color: $vert;
        }

        a {
            font-family: $font_primary;
            font-weight: 600;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: $noir;
            font-size: 12px;
            margin: 1rem;
            
            &:hover, &:focus{
                color: $vert;
                text-decoration: none;
            }
        }

        .sub-menu {
            position: absolute;
            background-color: $gris-clair;
            min-width: 200px;
            opacity: 0;
            transform: translateY(15px);
            pointer-events: none;
            transition: transform .3s ease, opacity .3s ease-in;
            z-index: 99;
            padding: 5px 0;

            li { 
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: flex-start;

                + li {
                    border-top: 1px solid rgba($gris, .1);
                }

                &::before{
                    content: "";
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                    margin: 0 0 0 20px;
                    background-color: $gris;
                }

                &:hover::before{
                    background-color: $vert;
                }

                &:nth-child(1)::before{
                    -webkit-mask: url('~@images/icon-societe.svg') no-repeat center;
                    mask: url('~@images/icon-societe.svg') no-repeat center;
                    mask-size: cover;
                    -webkit-mask-size: cover;
                }
                &:nth-child(2)::before{
                    -webkit-mask: url('~@images/icon-politique.svg') no-repeat center;
                    mask: url('~@images/icon-politique.svg') no-repeat center;
                    mask-size: cover;
                    -webkit-mask-size: cover;
                }
                &:nth-child(3)::before{
                    -webkit-mask: url('~@images/icon-opinions.svg') no-repeat center;
                    mask: url('~@images/icon-opinions.svg') no-repeat center;
                    mask-size: cover;
                    -webkit-mask-size: cover;
                }
                &:nth-child(4)::before{
                    -webkit-mask: url('~@images/icon-culture.svg') no-repeat center;
                    mask: url('~@images/icon-culture.svg') no-repeat center;
                    mask-size: cover;
                    -webkit-mask-size: cover;
                }
                &:nth-child(5)::before{
                    -webkit-mask: url('~@images/icon-economie.svg') no-repeat center;
                    mask: url('~@images/icon-economie.svg') no-repeat center;
                    mask-size: cover;
                    -webkit-mask-size: cover;
                }
                &:nth-child(6)::before{
                    -webkit-mask: url('~@images/icon-sport.svg') no-repeat center;
                    mask: url('~@images/icon-sport.svg') no-repeat center;
                    mask-size: cover;
                    -webkit-mask-size: cover;
                }
                &:nth-child(7)::before{
                    -webkit-mask: url('~@images/icon-sorties.svg') no-repeat center;
                    mask: url('~@images/icon-sorties.svg') no-repeat center;
                    mask-size: cover;
                    -webkit-mask-size: cover;
                }
                &:nth-child(8)::before{
                    -webkit-mask: url('~@images/icon-societe.svg') no-repeat center;
                    mask: url('~@images/icon-travail.svg') no-repeat center;
                    mask-size: cover;
                    -webkit-mask-size: cover;
                }
            }

            a {
                padding: 15px 15px;
                display: block;
                margin: 0;
            }
        }
        
        .menu-item-has-children {
            &:hover {
                .sub-menu {
                    opacity: 1;
                    pointer-events: all;
                    transform: translateY(0);
                }
            }
        }

        @media only screen and (max-width: 740px) {
            display: none;
        }
    }
}


