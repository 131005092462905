.equipe ul{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: space-between;
    padding: 0;
}

.membre{
    background-color: $gris-clair;
    width:23%;
    margin: 0 0 120px 0;
    img{
        width: 100%;
        height: auto;
        position: relative;
        z-index: 0;
        filter: grayscale(1);
        -webkit-filter: grayscale(1);
    }
    h2{
        font-weight: bold;
        color: $noir;
        font-size: 25px;
        margin-bottom: 20px;
        max-width: 40%;
    }
    &__content{
        padding: 20px;
        position: relative;
    }
    &__fonction{
        font-size: 12px;
        color: $vert;
        font-weight: bold;
        text-transform: uppercase;
    }
    &__position{
        position: relative;
        max-height: 256px;
        overflow: hidden;
        
        &::after{
            content: "";
            position: absolute;
            z-index: 1;
            top: 100%;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 0;
            background-color: $noir;
            transition: all .3s ease-in-out .1s;
        }
    }
    &__description{
        opacity: 0;
        font-size: 16px;
        width: auto;
        color: #fff;
        position: absolute;
        left: 20px;
        right: 20px;
        bottom: 0px;
        z-index: 2;
        transition: all .3s ease-in-out;
    }
    
    &__social{
        position: absolute;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
        top: -24px;
        background-color: #fff;
        padding: 10px 10px 5px;
        border-radius: 14px;
        a{
            width: 20px;
            height: 20px;
            &:hover g{
                fill: #0b66c2;
            }
            &:nth-child(2){
                margin-left: 20px;
                &:hover g{
                    fill: #1da1f1;
                }
            }
        }
    }
    &:hover, &:focus{
        .membre__position:after{
            top: 0;
            height: 100%;
            transition: all .3s ease-in-out;
        }
        .membre__description{
            opacity: 1;
            bottom: 20px;
            transition: all .3s ease-in-out .1s;
        }
    }
    &:last-child h2{
        max-width: 100%;
    }
}

@media only screen and (max-width: 1200px) {
    .membre{
        width: 31%;
        &:nth-child(7){
            margin-left: 17%;
        }
        &:nth-child(8){
            margin-right: 17%;
        }
    }
    
}


@media only screen and (max-width: 990px) {
    .membre{
        width: 46%;
        &:nth-child(7){
            margin-left: 0;
        }
        &:nth-child(8){
            margin-right: 0;
        }
    }
}

@media only screen and (max-width: 768px) {
    .membre{
        width: 85%;
        margin: 0 0 30px 0;
    }
    .equipe ul{
        justify-content: center;
    }
}