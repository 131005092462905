footer {
    padding: 3em 0;
    line-height: 1.4em;

    h1 {
        font-size: 20px;
        font-weight: bold;
        color: $noir;
        margin-bottom: 40px;
    }

    p {
        font-family: $font_secondary;
        font-size: 14px;
        font-weight: 300;
        color: $noir;
    }

    .btn {
        margin-top: 30px;
    }

    nav {
        li {
            display: block;
            margin-bottom: 15px;
        }

        a {
            color: $noir;
            display: block;
            padding: 0.3em 0;
            text-transform: uppercase;
            font-size: 12px;
            letter-spacing: 2px;
            font-weight: 300;

            &:hover,
            &:focus {
                color: $vert;
                text-decoration: none;
            }
        }

        .svg-inline--fa {
            display: none;
        }

        .sub-menu {
            display: none;
        }
    }

    #slides {
        position: relative;
        height: 120px;
        padding: 0px;
        margin-bottom: 20px;
        list-style-type: none;
    }
    
    .slide {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;
    
        -webkit-transition: opacity 1s;
        -moz-transition: opacity 1s;
        -o-transition: opacity 1s;
        transition: opacity 1s;
    }

    .slide img {
        width: 100%; 
        height: auto;
    }

    .showing {
        opacity: 1;
        z-index: 2;
    }
}

.soutiens {
    a {
        margin: 0 3em 2em 0;
        display: inline-block;
    }
}

.end {
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
    background-color: $noir;
    color: #FFF;
    padding: 15px 0;
    font-weight: 400;

    a {
        color: #FFF;
        font-weight: 400;
    }
}

@media only screen and (max-width: 768px) {
    footer {

        .soutiens {
            a {
                margin: 0;
                display: block;
            }
        }

        .slide {

            a {
                text-align: center;
            }
            
            img {
                max-height: 120px;
                width: auto;
            }
        }
        
        .col-md-3 {
            margin-bottom: 3em;
        }
    }
}