.single {
  .single_container {
    background: $vert;

    * {
      color: $gris-clair !important;
    }
  }

  .single_container_hero {
    padding: 4em 0 0 !important;


    .embed-container {
      position: relative;
      top: 50px;
    }
  }

  .container {
    .single_row {
      justify-content: space-between;

      iframe {
        width: 100%;
        border: none;
      }
    }
    .single_container_content {
      margin-bottom: 70px;
      padding: 0;

      p {
        font-size: 16px !important;
        font-weight: 300;
        font-family: $font_secondary;
        color: #1C3433;
        margin: 30px 0;
        line-height: 30px;
      }

      blockquote, blockquote p {
        font-size: 20px !important;
        line-height: 30px;
        font-family: $font_secondary;
        color: #1C3433;
        font-style: italic;
        font-weight: 300;
        position: relative;
        line-height: 30px;
      }

      blockquote {
        border-left: 2px solid $gris-clair;
      }

      blockquote p {
        margin-left: 90px;
        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          width: 30px;
          height: 30px;
          background: url('~@images/icon-quote.svg') no-repeat center; 
          background-size: cover;
          left: -50px;
          top: 0;
        }
      }

            @media only screen and (max-width: 768px) {
        blockquote, blockquote p{
          font-size: 16px !important;
        } 

        blockquote p {
          margin-left: 60px;
        }
    }

    }
    .single_container_galery {

      .block-image {
        width: 100%;
        height: 265px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

    }
  }

  .lasts_container {
    article {
      &:hover,
      &:focus {
          background-color: $vert; 
          
          .block_title_more {
              color: white;
          }

          .img-container {
              img {
                  transform: scale(1.05);
              }
          }

          a {
            text-decoration: none;
          }

      }
    }

    .container-podcasts {
      margin-top: 2em;
    }

  }
  
  .lastsVideo{
    width: 100%;
    padding-bottom: 0 !important;
    padding-right: 15px;
    padding-left: 15px;
    .custom_episodes{
      flex-direction: row !important;
      justify-content: flex-start;
      display: flex;
      flex-wrap: wrap;
      margin-left: -3%;
      margin-bottom: -50px;

      @media only screen and (max-width: 1200px){
        margin-left: -5%;
      }

      @media only screen and (max-width: 985px){
        margin-left: 0%;
      }
    }

    .videoBlock{
      min-width: 22%;
      max-width: 300px;
      margin-left: 3%;
      margin-bottom: 50px;

      img{
        width: 100% !important;
      }

      .article__content{
        padding: 20px !important;
        flex: 1;
      }

      @media only screen and (max-width: 1200px){
        min-width: 45%;
        max-width: 45%;
        margin-left: 5%;
      }

      @media only screen and (max-width: 985px){
        min-width: 100%;
        max-width: 100%;
        margin-left: 0%;
      }
    }

    .custom-wrapper-episode-video{
      flex-direction: column;
    }

  }


  h1 {
    z-index: 1;
  }
  
  .swiper-button-next:after, 
  .swiper-button-prev:after {
    color: $gris-clair;
    background: $vert;
    padding: 10px;
    border-radius: 5px;
  }

  .swiper-button-prev {
    left: 20px;
  }
  .swiper-button-next {
    right: 20px;
  }


}

.custom-video {
  padding: 0 !important;
}
@media only screen and (max-width: 985px) {
  .custom-video {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .contentEmission {
    padding: 15px !important;
  }
}

.block_content_more_image {
  img {
      height: 300px;
      display: block;
      object-fit: cover;
      position: relative;
  }
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: url('~@images/icon-podcast.svg') no-repeat;
    width: 40px;
    height: 40px;
    left: 10px;
    top: 10px;
    z-index: 4;
  }
}


.episode.lasts_container-podcasts .block_content_more_image img {
  height: 300px;
  display: block;
  object-fit: cover;
}

.episode.lasts_container-video {

  .img-container {
    position: relative;

    img {
      height: 163px;
      display: block;
      object-fit: cover;
      position: relative;
  }

  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    background: url('~@images/icon-camera.svg') no-repeat;
    width: 40px;
    height: 40px;
    left: 10px;
    top: 10px;
    z-index: 4;
  }

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: url('~@images/icon-play-small.svg') no-repeat;
    width: 60px;
    height: 60px;
    left: 40%;
    top: 30%;
    z-index: 4;
  }
  }

}

.lasts_container-podcasts {
  .img-container {
    position: relative;

    img {
      height: 163px;
      display: block;
      object-fit: cover;
      position: relative;
  }

  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    background: url('~@images/icon-camera.svg') no-repeat;
    width: 40px;
    height: 40px;
    left: 10px;
    top: 10px;
    z-index: 4;
  }
  }

}

.block_content_more_video, .block_content_videos {
  .img-container {
    position: relative;

    img {
      height: 163px;
      display: block;
      object-fit: cover;
      position: relative;
  }

  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    background: url('~@images/icon-camera.svg') no-repeat;
    width: 40px;
    height: 40px;
    left: 10px;
    top: 10px;
    z-index: 4;
  }
  }
}

.episode.lasts_container article {
  &:hover,
  &:focus {
      background-color: $vert; 
      
      .block_title_more {
          color: white;
      }

      .img-container {
          img {
              transform: scale(1.05);
          }
      }

      a {
        text-decoration: none;
      }

  }
}

.gprev, .gnext, .gclose {
  background-color: $vert !important;
}
.goverlay {
  background: rgba(28, 52, 51, 0.6) !important;
  backdrop-filter: blur(7px) !important;
}

.green{
  font-size: 0.6875em;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.0625em;
  color: $vert;
  display: inline-block;
  padding: 0.15em 0;
}