// @import '../css/_old-compile.css';

//----settings----//
@import 'theme/settings/colors';
@import 'theme/settings/fonts';
@import 'theme/settings/body';
@import 'theme/settings/title';

//----common----//
@import 'theme/common/footer';
@import 'theme/common/header';
@import 'theme/common/components/burger';
@import 'theme/common/components/nav_mobile';
@import 'theme/common/components/btn';
@import 'theme/common/components/rubriquemenu';
@import 'theme/common/components/article';
@import 'theme/common/components/newsletter';
@import 'theme/common/components/socialbox';
@import 'theme/common/components/aboutbox';
@import 'theme/common/components/newsbox';
@import 'theme/common/components/print';
@import 'theme/common/components/search';
@import 'theme/common/components/social';
@import 'theme/common/components/subside';
@import 'theme/common/components/autres';
@import 'theme/common/components/pagination';

@import 'theme/common/components/alert';

//----pages----//
@import 'theme/pages/front';
@import 'theme/pages/search';
@import 'theme/pages/single';
@import 'theme/pages/contact';
@import 'theme/pages/don';
@import 'theme/pages/equipe';
@import 'theme/pages/indexEmission';
@import 'theme/pages/indexEpisode';
@import 'theme/pages/singleEmission';
@import 'theme/pages/singleEpisode';



