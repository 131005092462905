body {
    font-family: $font_primary;
    line-height: 1.5em;
    border-top: 3px solid $vert;
    overflow-x: hidden;
}

.overflow {
    max-width: 100vw;
    overflow : hidden;
}

section {
    padding: 2em 0;

    &.last {
        padding: 4rem 0;
    }
}

.onesignal-bell-svg{
    circle{
        fill: $vert !important;
    }
}

.col-md-3 {
    margin-bottom: 20px;
}

.mgbutton{
    background-color: $vert !important;
    &:hover{
        background-color: #fff !important;
        color: $vert !important;
    }
}

.change-settings-button:hover{
    color: $vert !important;
}

@media screen and (min-width: 1200px) {
    .container {
        max-width: 1400px;
    }
}