.newsletter {
    background-color: $vert;
    color: #FFF;
    padding: 40px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    h2, p {
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 1em;
        font-family: $font_primary;
        line-height: 1.2;
    }
    p{
        margin: 0;
    }

    .btn {
        margin: 50px auto 0;
        &:hover {
            color: white;
        }
    }

    &__contact{
        padding: 66px 50px;
    }

    &--home {
        height: 100%;
        margin: 0 !important;

        .btn {
            color: $noir;
        }
    }
}

@media only screen and (max-width: 990px) {
    .newsletter {
        margin: 0 15px 0;
    }

    .newsletter--home {
        margin: 25px 0 0 0 !important;
    }
}