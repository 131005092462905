.rubriquemenu {
    background-color: $vert;
    // margin-bottom: 3em;

    .menu {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    ul {
        margin-bottom: 0;
    }

    li {
        display: inline-block;
        margin: 0 0.75em;
        a {
            display: block;
            margin: 0;
            padding: 1em 0;
            text-transform: uppercase;
            color: #FFF;
            font-size: 0.8125em;

            &:hover {
                color: #FFF;
                text-decoration: none;
            }
        }
        &:first-child{
            margin-left: 0;
        }
        &:last-child{
            margin-right: 0;
        }
    }

    .current {
        a {
            color: #FFF;
            font-weight: 600;
            border-bottom: 2px solid #FFF;
        }
    }

    @media only screen and (max-width: 740px) {
        margin-top: 50px;
    }
}
