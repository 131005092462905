.social {
    display: inline-block;
    margin-left: 15px;

    ul {
        margin: 0;
    }

    li {
        display: inline-block;
    }

    a {
        color: #1C1C1C;
        display: block;
        margin: 0 0.5em;
        transition: .3s ease;

        svg path {
            transition: fill .3s ease;
        }

        &:hover{
            svg path {
                fill: $vert;
            }
        }

        svg {
            vertical-align: middle;
        }
    }
}