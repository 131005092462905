.btn {
    font-size: 0.75em;
    font-weight: 800;
    text-transform: uppercase;
    display: table;
    padding: 20px 30px;
    background-color: $vert;
    color: white;
    border-radius: 500px;
    letter-spacing: 2px;
    position: relative;
    bottom: 0;
    transition: .3s ease;
    line-height: .8;

    &--center {
        margin: 0 auto;
    }

    &:hover {
        color: white;
        background-color: $noir;
        transition: .3s ease;
        color: #fff;
    }

    &__secondaire{
        color: $noir;
        background-color: #fff;
        //border: 2px solid $noir;

        &:hover, &:focus {
            //background-color: $noir;
            color: #fff;
            transition: .3s ease;
        }
    }

    &:focus {
        outline: $noir;
        outline-offset: 1px;
    }
}