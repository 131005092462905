.search {
    display: inline-block;
    margin-right: 1em;

    label {
        opacity: 0;
        pointer-events: none;
        height: 0;
        width: 0;
    }

    form {
        position: relative;
    }

    input[type="search"] {
        width: 170px;
        background: #E8E8E8;
        border: none;
        border-radius: 10px;
        padding: 5px 15px;
        outline: none;


        &:focus{
            border-color: $vert;
        }

        &::placeholder{
            // font-style: italic;
            font-size: 11px;
            font-family: $font_primary;
        }
    }

    input[type=image] {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        z-index: 1;

        opacity: .5;
    }
}