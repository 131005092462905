.margeTop{
    transform: translateY(-120px);
}

.margeLeft{
    margin-left: 3%;
}

a.journal_logo {
    position: absolute;
    right: 0;
}

.hero{
    min-height: 400px;
    max-height: 520px;
    overflow: hidden;
    background: url('~@images/hero.svg') no-repeat center; 
    background-size: cover;

    @media screen and (max-width: 380px) {
        min-height: 200px;
    } 
}

.heroArticle{
    position: relative;
    z-index: 0;
    &::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 1;
        background-color: #fff; 
        z-index: 1;
        animation: backgroundColor .5s .5s ease forwards;
    }
}

@keyframes backgroundColor{
    to{
        opacity: 0;
    }
}

.page-nav{
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    .prevpage{
        &::before{
            display: inline-block;
            content: "";
            width: 16px;
            height: 12px;
            background: url('~@images/icon-arrow-left.svg');
            vertical-align: middle;
            margin-right: 10px;
            transition: .3s ease;
        }
        &:hover::before{
            transform: translateX(-10px);
            transition: .3s ease;
        }
    }
    .nextpage{
        &::after{
            display: inline-block;
            content: "";
            width: 16px;
            height: 12px;
            background: url('~@images/icon-arrow-right.svg');
            vertical-align: middle;
            margin-left: 10px;
            transition: .3s ease;
        }
        &:hover::after{
            transform: translateX(10px);
            transition: .3s ease;
        }
    }
}

.single{
    &__content {
        background-color: $gris-clair;
        padding: 50px;
        margin-bottom: 50px;

        /*@media screen and (min-width: 1025px) {
            padding: 50px 250px; 
        }*/

        .infos {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            .credit {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;

                background: white;
                padding: 5px 15px;
                margin-left: auto;
                border-radius: 6px;

                font-family: $font_secondary;
                font-size: 10px;
                font-weight: 300;

                @media only screen and (max-width: 570px) {
                    margin-top: 20px;
                    margin-left: 0;
                }

                p {
                    font-weight: 600;
                    padding-left: 5px;
                    margin: 0;

                    a {
                        color: black;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .date{
        float: right;
        font-size: 12px;
        color: $gris;
        font-weight: 800;
        letter-spacing: 2px;
    }
    .cat{
        width: auto;
        display: inline-block;
        a{
            font-size: 12px;
            font-weight: 800;
        }
    }
    h1{
        font-size: 50px;
        line-height: 1.1;
        color: $noir;
        margin: 5px 0 25px;
        font-weight: 700;
        font-family: $font_primary;
    }
    .span-reading-time{
        text-transform: uppercase;
        color: $noir;
        font-size: 12px;
        line-height: 2;
        font-weight: 600;
        letter-spacing: 2px;
    }
    .intro{
        font-style: italic;
        font-family: 'Montserrat', serif;
        font-size: 1.15em;
        color: $noir;
        letter-spacing: 0.0625em;
    }

    &__info{
        padding: 20px;
        background-color: #fff;
        margin: 30px 0 50px;
        border-radius: 14px;
        div{
            padding-right: 0;
            padding-left: 0;
        }
        img{
            width: 60px;
            height: 60px;
            display: block;
            float: left;
            margin-right: 15px;
            clip-path: circle(50%);
            filter: grayscale(1);
            -webkit-filter: grayscale(1);
        }
        span{
            display: block;
        }
        .job{
            color: $noir;
            font-size: 14px;
            font-family: $font_secondary;
            font-weight: 300;
        }
        .name{
            font-size: 15px;
            font-family: $font_secondary;
            font-weight: 800;
            a{
                color: $noir;
                transition: .3s ease;
                &:hover{
                    text-decoration: none;
                    color: $vert;
                    transition: .3s ease;
                }
            }
        }
    }

    .italicP{
        p:first-child{
            font-size: 18px;
            font-weight: 800;
            font-style: italic
        }
    }
    .article__body{
        color: $noir;

        &::before{
            content: "";
            display: inline-block;
            width: 100%;
            height: 1px;
            background-color: #d9d9d9;
            margin-bottom: 50px;
        }
        p{
            font-family: $font_secondary;
            font-size: 16px;
            font-weight: 300;
            line-height: 1.5;
            margin-bottom: 30px;
        }
        strong{
            font-weight: 600;
        }
        a{
            color: $vert;
        }
        h2, h3, h4, h5, h6{
            font-size: 40px;
            font-family: $font_primary;
            font-weight: 800;
            margin: 50px 0 30px;
            max-width: 75%;
        }
        h3{
            font-size: 25px;
            margin: 0 0 8px;
            font-weight: 500;
        }
        h4{
            font-size: 20px;
            margin: 0 0 8px;
            font-weight: 500;
        }
        h5{
            font-size: 18px;
            margin: 0 0 8px;
            font-weight: 500;
        }
        h6{
            font-size: 16px;
            margin: 0 0 8px;
            font-weight: 500;
        }
        blockquote {
            position: relative;
            padding-left: 50px;
            p{
                font-size: 18px;
                font-style: italic;
                font-weight: 800;
            }
            &::before{
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 1px;
                height: 100%;
                background-color: $noir;
                opacity: .2;

            }
        }
        .newsletter-bloc{
            font-family: $font_primary;
            background-color: $vert;
            color: #fff;
            padding: 25px 50px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            margin: 50px 0;
            div{
                width: 60%;
            }
            h3{
                display: none;
            }
            p{
                font-family: $font_primary;
                font-size: 20px;
                line-height: 1.5;
                margin-bottom: 0;
            }
            .btn{
                color: $noir;
                background-color: #fff;
                &:hover, &:focus {
                    color: $vert;
                    transition: .3s ease;
                }
            }
        }
        iframe{
            max-width: 100%;
        }
    }
    .partager{
        display: flex;
        flex-wrap: nowrap;
        font-size: 20px;
        font-weight: 800;
        color: $noir;
        border-top: 1px solid #E9E9E9;
        border-bottom: 1px solid #E9E9E9;
        padding: 50px 0;
        margin: 50px 0 0;
        ul{
            display: flex;
            flex-wrap: nowrap;
            margin: 0;
            li{
                margin: 0 0 0 30px;
                g{
                    transition: .3s ease;
                }
                &:hover g{
                    fill: $vert;
                    transition: .3s ease;
                }
            }
        }
    }
    .tags{
        padding: 0;
        margin: 50px 0 0;
        line-height: 2;
        span{
            display: flex;
            flex-wrap: nowrap;
        }
        ul{
            margin: 0;
        }
        li{
            margin-left: 20px;
        }
        a{
            color: $vert;
        }
    }
    &__footer{
        color: #6E6E6E;
        font-size: 0.75em;
        line-height: 1.5em;
        margin-top: 50px;
        a{
            color: $vert;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .col-md-3{
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .margeLeft{
        margin-left: 0%;
    }
}

@media only screen and (max-width: 990px) {
    .margeTop{
        transform: translateY(-60px);
    }

    .margeTop{
        .col-md-3{
            max-width: 100%;
            flex: 0 0 100%;
            padding-right: 10px;
            padding-left: 10px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .col-md-3{
        max-width: 50%;
        flex: 0 0 50%;
        padding-right: 10px;
        padding-left: 10px;
    }
    .page-nav{
        .btn{
            font-size: 0;
            letter-spacing: 0;
        }
        .prevpage{
            &::before{
                margin-right: 0px;
            }
        }
        .nextpage{
            &::after{
                margin-left: 0px;
            }
        }
    }
    .single{
        &__content {
            padding: 30px 15px;
        }
        .date{
            float: none;
            margin-top: 10px;
            display: block;
        }
        h1{
            font-size: 40px;
            margin: 20px 0 30px;
        }
        &__info{
            div:first-child{
                margin-bottom: 20px;
            }
        }
        .article__body{
            .newsletter-bloc{
                justify-content: center;
                div{
                    width: 100%;
                    margin-bottom: 20px;
                }
            }
            ul{
                list-style: inside;
            }
        }
    }
}

@media only screen and (max-width: 380px) {
    .col-md-3{
        max-width: 100%;
        flex: 0 0 100%;
        padding-right: 10px;
        padding-left: 10px;
    }

    .hero{
        min-height: 200px;
        max-height: 300px;
    }
}