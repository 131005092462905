.subside {
    padding-bottom: 6em;
    font-family: $font_primary;
    background: $vert;
    padding: 60px 0;
    color: white;

    .wrapper {

        max-width: 1400px;
        margin: 0 auto;

        display: flex;
        justify-content: space-evenly;
        align-items: center;

        @media only screen and (max-width: 740px) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            text-align: center;
        }

        .left {
            width: 40%;
            font-size: 25px;
            font-weight: bold;
            padding: 0 40px;
            line-height: 30px;

            @media only screen and (max-width: 740px) {
                width: 100%;
            }
        }

        .right {
            width: 60%;
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;

            @media only screen and (max-width: 740px) {
                width: 100%;
                margin-top: 40px;

                flex-direction: column;
                align-items: center;
            }

            li {
                width: 300px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                text-align: center;

                p {
                    font-size: 25px;
                    font-weight: bold;
                    margin-top: 40px;
                    line-height: 30px;

                }

                + li {
                    margin-left: 20px;

                    @media only screen and (max-width: 740px) {
                        margin-left: 0;
                        margin-top: 20px;
                    }
                }

                .btn {
                    background: white;
                    color: $noir;

                    &:hover {
                        background: white;
                        color: white;
                    }
                }
            }
        }
    }
}