.mobile_nav {
  width: calc(100% + 40px);
  height: calc(100vh - 72px);
  overflow: auto;

  position: absolute;
  top: 74px;
  left: -20px;
  right: -20px;
  z-index: 99;
  background: $noir;
  
  opacity: 0;
  transform: translateX(-110%);
  transition: transform .3s ease, opacity .3s ease;

  .isActive & {
    opacity: 1;
    transform: translateX(0);
  }

  @media only screen and (min-width: 740px) {
    display: none;
  }

  .menu {
    height: 100%;
    padding: 35px 25px;

    &-item {
      border-bottom: 1px solid rgba(white, .1);

      a {
        display: block;
        color: white;
        font-size:  25px;
        font-weight: bold;

        padding: 20px 0;

        
        &:hover,
        &:focus {
          color: white;
          text-decoration: none;
        }
      }
    }

    &-item-has-children {

      > a {
        position: relative;

        &:after {
          content: '';
          display: block;
          width: 16px;
          height: 16px;
          background: url('~@images/icon-plus.svg');
          z-index: 1;

        position: absolute;
        top: 20px;
        right: 0;
        }
      }

      a {
        svg {
          display: none;
        }
      }
    }
  }

  .sub-menu {
    padding-left: 25px;

    .menu-item {
      border-bottom: none;

      a {
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }
  }
}