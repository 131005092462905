.newsbox{
    padding: 0;
    background-color: $gris-clair;
    margin-bottom: 50px;
    position: relative;
    transition: .3s ease;
    img{
        width: 100%;
        height: auto;
        margin-bottom: 20px;
    }
    a{
        color: $noir;
        &:hover{
            text-decoration: none;
        }
    }
    h2{
        font-size: 20px;
        padding: 20px;
        font-weight: 800;
    }
    span{
        padding: 20px 20px 0;
        a{
            font-size: 12px;
            text-transform: uppercase;
            color: $vert;
            font-weight: 800;
            font-family: $font_primary;
            transition: .3s ease;
        }
    }
    div{
        background-color: #fff;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        position: relative;
        margin: 0 20px;
        float: right;
        transform: translateY(-100px);
        img{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 40px;
            height: 40px;
        }
    }
    &:hover{
        background-color: $vert;
        transition: .3s ease;
        span{
            a{
                color: #fff;
                transition: .3s ease;
            }
        }
    }
}

@media only screen and (max-width: 990px) {
    .newsbox{
        margin: 0 15px 30px;
    }
}