article {
    background: #FFFFFF;
    // box-shadow: 0 2px 4px 0 rgba(223,223,223,0.50);
    position: relative;
    bottom: 0;
    // border-radius: 3px;
    transition: .3s ease;

    // &:hover {
    //     box-shadow: 0 4px 4px 0 rgba(223,223,223,0.80);
    //     bottom: 2px;
    // }

    .article__content {
        padding: 0.975em;
        position: relative;
    } 

    h1 {
        font-weight: 600;
        font-size: 1em;
        margin:0.5em 0 0;

        a {
            color: $noir;

            &:hover{
                text-decoration: none;
                color: $vert;
            }
        }
    }

    .cat {
        color: $vert;

        a {
            font-size: 0.6875em;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 0.0625em;
            color: $vert;
            display: inline-block;
            padding: 0.15em 0;
        }

        &:hover {
            text-decoration: none;
            color: white;
        }

        &.societe {
            color: #D0021B;
            border-color: #D0021B;
        }

        &.opinion {
            color: #4A90E2;
            border-color: #4A90E2;
        }

        &.sortie {
            color: #F5A623;
            border-color: #F5A623;
        }
    }

    p {
        font-size: 0.875em;
        margin-bottom: 1em;
    }

    img{
        width: 100%;
        height: auto;
        display: block;
    }

    .container--img{
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            min-height: 400px;
            height: auto;
            display: block;
            position: relative;
            z-index: 2;
        }
        @media screen and (max-width: 650px) {
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: auto;
                height: 100%; 
                max-height: 520px;
            }
        } 

        @media screen and (max-width: 380px) {
            img{
                width: auto;
                height: 100%; 
                min-height: 200px;
            }
        } 
    }

    .img-container {
        display: block;
    }
}

.last {

    .row {
        height: 100%;
    }

    .custom-height {
        height: auto;
        @media screen and (max-width: 768px) {
            height: 250px !important;
        }
    }

    &__img  {
        display: block;
        height: 100%;
        width: 100%;
        background-position: center;
        background-size: cover;
    }

    article {
        height: 100%;

        h1 {
            font-size: 1.5em;
            line-height: 1.2em;
            margin-bottom: 1em;
        }

        p {
            font-family: $font_secondary;
            font-weight: 300;
        }
    }

    .journal {
        position: absolute;
        display: block;
        
        right: calc(100% + 50px);
        bottom: 30px;

        padding: 15px;
        background-color: white;
        border-radius: 100%;
        overflow: hidden;
        
        img {
            width: 60px;
            height: 60px;
        }

        @media screen and (max-width: 768px) {
            right: 35px;
            top: -110px;
            bottom: inherit;
        }
        
    }
    

    .btn {
        margin-left: auto;
        margin-bottom: 2em;
    }

    &__content {
        padding: 40px;

        p {
            color: $noir;
        }
    }
}



.lasts {
    article {
        height: 100%;
        background: $gris-clair;
        overflow: hidden;

        .img-container {
            img {
                transition: transform .3s ease;
            }
        }

        &:hover,
        &:focus {

            .img-container {
                img {
                    transform: scale(1.05);
                }
            }

            .article__content {
                a {
                    text-decoration: underline;
                }
            }
        }

        .journal {
            position: absolute;
            display: block;
            right: 20px;
            top: -90px;

            padding: 15px;
            background-color: white;
            border-radius: 100%;
            overflow: hidden;
    
            img {
                width: 40px;
                height: 40px;
            }
        }

        p a {
            color: $noir;
            font-size: 20px;
            font-weight: bold;
            text-decoration: none;
        }
    }
}

.formatCustom{
    img{
        height: 100% !important;
    }
}