.content.single {

    .block_single_title {
        text-transform: uppercase;
        font-size: 12px;
    }

    .block_single_title_emission {
        font-size: 50px;
        font-weight: bold;
      }

    .block_single_description {
        font-size: 20px;
        font-family: $font_primary;
        line-height: 1.5;
    }

    .custom_img_header {
        margin-top: 30px;
    }

    .custom_episodes {
        flex-direction: column;
        margin-top: 20px;

        .col-md-7 {
            padding: 0;
        }
    }
    .title_episodes_wrapper {
        font-weight: 800;
        font-size: 20px;
    }
    .lasts-episodes-container {
        margin-top: 70px;
    }

    .lasts-episodes-container-video  {
        .custom_episodes {
            display: flex !important;
            flex-direction: row !important;
            flex-wrap: wrap !important;
        }
        .custom-wrapper-episode-video {
            display: flex;
            flex-direction: column !important;

            img {
                width: 100% !important;
            }
        }
        .col-md-3 {
            padding: 0 !important;
        }
    }

    .custom-wrapper-episode{
        display: flex;
        margin-bottom: 20px;

        a.img-container {
            position: relative;
            &::after {
                content: "";
                display: inline-block;
                position: absolute;
                background: url('~@images/icon-podcast.svg') no-repeat;
                width: 40px;
                height: 40px;
                left: 10px;
                top: 10px;
                z-index: 4;
              }
        }

        img {
            width: 150px;
            height: 150px;
            object-fit: cover;
            object-position: center;
        }
        .article__content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            p {
                font-family: $font_primary;
            }

            span {
                font-family: $font_primary;
                letter-spacing: 2px;
                text-transform: uppercase;
            }
        }
        
    }

    .custom-wrapper-episode-video {
        display: flex;
        margin-bottom: 20px;
        
        a.img-container {
            position: relative;
        

            &::after {
                content: "";
                display: inline-block;
                position: absolute;
                background: url('~@images/icon-play-small.svg') no-repeat;
                width: 60px;
                height: 60px;
                left: 40%;
                top: 30%;
                z-index: 4;
              }
        }
        img {
            width: 150px;
            height: 150px;
            object-fit: cover;
            object-position: center;
        }
        .article__content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            p {
                font-family: $font_secondary;
            }

            span {
                font-family: $font_primary;
                letter-spacing: 2px;
                text-transform: uppercase;
            }
        }
    }

    .custom-width-emission-podcast {
        padding: 0 !important;
        position: relative;
        &::after {
          content: "";
          display: inline-block;
          position: absolute;
          background: url('~@images/icon-podcast.svg') no-repeat;
          width: 40px;
          height: 40px;
          left: 10px;
          top: 10px;
          z-index: 4;
        }

        img {
            height: 400px;
            object-fit: cover;
            object-position: center;
        }
      }

      .custom-width-emission-video-after {
        position: relative;
        padding: 0;

        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            background: url('~@images/icon-camera.svg') no-repeat;
            width: 40px;
            height: 40px;
            left: 10px;
            top: 10px;
            z-index: 4;
        }

          a.last__img {
            //min-height: 420px !important;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
      }

      .custom-width-emission-video {
        position: relative;
        padding: 0;

        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            background: url('~@images/icon-camera.svg') no-repeat;
            width: 40px;
            height: 40px;
            left: 10px;
            top: 10px;
            z-index: 4;
        }
         &::after {
            content: "";
            display: inline-block;
            position: absolute;
            background: url('~@images/icon-play-small.svg') no-repeat;
            width: 60px;
            height: 60px;
            left: 50%;
            top: 40%;
            z-index: 4;
          }

          a.last__img {
            //min-height: 420px !important;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

    @media only screen and (max-width: 768px) {
        .custom-wrapper-episode{
            flex-direction: column;
            img {
                width: 100%;
            }
        }
        
    }

    .taxoEmissionEpisodes {
        background-color: $gris-clair;
        border-radius: 14px;
        padding: 10px 30px;
    
        .sectionEmissionEpisodes {
            padding: 0 ;
        }
    
        .block_annee_prod,
        .block_num_episodes{
            margin-top: 30px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            position: relative;
            padding-bottom: 20px;
            p {
                letter-spacing: 2px;
                font-size: 12px;
                text-transform: uppercase;
            }
    
            span {
                font-family: $font_secondary;
                font-size: 15px;
            }
    
            &::after {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background-color: #d9D9D9;
                position: absolute;
                bottom: 0;
            }
        }
        .block_credits {
            letter-spacing: 2px;
            font-size: 12px;
            text-transform: uppercase;
        }
    
        .block_dedicace {
            p {
                font-size: 15px;
                font-family: $font_secondary;
                font-weight: 300;
            }
        }
    
        .info {
            display: flex;
            flex-direction: row;
            p, span {
              font-family: $font_secondary;
              font-size: 15px;
            }
    
            p {
                margin-right: 10px;
                font-weight: 300;
            }
    
            span {
                font-weight: bold;
            }
        }

    }

}