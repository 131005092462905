.contactdiv{
    background-color: $gris-clair;
    padding: 50px;

    h1{
        font-size: 50px;
        font-weight: 800;
        line-height: 1.1;
        color: $noir;
        margin: 0;
    }
}

.contactform{
    legend{
        font-size: 16px;
        font-family: $font_secondary;
        line-height: 1.8;
        font-weight: 300;
        margin: 20px 0 50px;
    }

    label{
        font-size: 16px;
        font-weight: 800;
        margin: 0 0 10px 0;
        span{
            font-size: 14px;
            font-family: $font_secondary;
            line-height: 1.7;
            font-weight: 300;
        }
    }
    input, textarea{
        width: 100%;
        padding: 10px 15px;
        border-radius: 10px;
        border: 1px solid $noir;
        margin: 0 0 30px 0;
    }

    input[type=checkbox]{
        width: 20px;
        height: 20px;
        margin: 0 10px 0 0;
        border: 1px solid $noir;
    }

    input[type=checkbox]:checked{
        background-color: red;
    }

    .btn{
        display: table;
        width: auto;
        padding: 20px 30px;
        margin: 50px auto 0;
        border-radius: 100px;
        border: none;
    }
}

@media only screen and (max-width: 990px) {
    .col-md-8, .col-md-4{
        max-width: 100%;
        flex: 0 0 100%;
        padding-right: 10px;
        padding-left: 10px;
    }
    .contactdiv{
        margin-bottom: 30px;
        h1{
            font-size: 40px;
        }
    }
}

@media only screen and (max-width: 380px) {
    .contactdiv{
        padding: 20px 15px;
    }
}