.aboutbox{
    font-size: 14px;
    line-height: 1.7;
    background: $gris-clair;
    color: $noir;
    margin: 0 0 50px;
    padding: 30px;

    div{
        background-color: #fff;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        position: relative;
        margin: 0 auto 2em;
    }
    img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70px;
        height: auto;
        max-height: 70px;
    }
    .btn{
        margin: 30px auto 0;
    }
}

@media only screen and (max-width: 990px) {
    .aboutbox{
        margin: 50px 15px 30px;
    }
}