.socialbox{
    background-color: $gris-clair;
    margin-top: 50px;
    padding: 30px;
    h2{
        font-size: 14px;
        text-align: left;
        font-family: $font_secondary;
        font-weight: 300;
        line-height: 1.7;
        color: $noir;
        margin-bottom: 30px;
    }
    ul{
        margin: 0 auto;
        text-align: center;
        display: flex;
        justify-content: center;
        li{
            width: 40px;
            height: 40px;
            margin: 0;
            &:nth-child(2){
                margin: 0 30px;
            }
            a{
                margin: 0;
                width: 40px;
                height: 40px;
                .hover__fcb, .hover__twt, .hover__inst1, .hover__inst2, .hover__inst3{
                    transition: all 5s ease;
                    fill: $noir;
                }
                &:hover, &:focus{
                    .hover__fcb{
                        fill: #1877f2;
                        transition: .3s ease;
                    }
                    .hover__twt{
                        fill: #1da1f1;
                        transition: .3s ease;
                    }
                    .hover__inst1{
                        fill: url(#radialGradient-1);
                        transition: all 5s ease;
                    }
                    .hover__inst2{
                        fill: url(#radialGradient-2);
                        transition: all 5s ease;
                    }
                    .hover__inst3{
                        fill: #654C9F;
                        transition: .3s ease;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 990px) {
    .socialbox{
        margin: 30px 15px 0;
    }
}