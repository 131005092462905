.traducteur{
    &__content{
        padding: 2em 4em;
        background-color: #FFF;
        box-shadow: 0 2px 4px 0 rgba(223,223,223,0.50);
    }
    .title{
        margin-bottom: 1em;
    }
}
.tradaside{
	margin-top: 4.4em;
	&__contenu{
		background-color: $vert;
	    color: #FFF;
	}
	img{
		width: 100%;
		height: auto;
	}
	&__content{
		color: #FFF;
		padding: 1.5em;
	}
	h2{
		font-weight: bold;
		color: #FFF;
		margin-bottom: 1em;
	}
	&__fonction{
		font-size: 0.8125em;
		color: #FFF;
		margin-bottom: 1em;
		display: block;
	}
	p{
		color: #FFF;
	}
}