.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  
  font-family: $font_primary;
  font-weight: 900;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 40px 0;

  .page-numbers {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 60px;
    height: 60px;
    background: $vert;
    border-radius: 100%;
    color: white;
    margin-right: 10px;
    transition: background-color .3s ease;

    &:last-child {
      margin-right: 0;
    }

    &:hover,
    &:focus {
      text-decoration: none;
      background: $noir;
    }

    &.current {
      background: $noir;
    }
    
    &.dots {
      background: $gris-clair;
      color: $vert;
    }

    &.prev {

    }

    &.next,
    &.prev {
      width: auto;
      border-radius: 100px;
      padding: 20px 30px;
    }
  }
}