.burger {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: $vert;
  border-radius: 15px;
  padding: 12px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;

  @media only screen and (min-width: 740px) {
    display: none;
  }

  .line {
    display: block;
    background: white;
    height: 2px;
    width: 100%;
  }

}

// Nav active 

.overflow {
  .burger {}
}